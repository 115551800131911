import React, { useEffect, useContext } from "react"
import { graphql } from "gatsby"
import Markdown from "markdown-to-jsx"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import { formatRichText } from "../utils"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"
import '../functions.js';

const Conditions = ({ data }) => {
  const { plainpage, homepages } = data.strapi
  const { language, heading, richText } = plainpage
  const { header, footer } = homepages[0]
  const content = formatRichText(richText)

  const dispatch = useContext(GlobalDispatchContext)
  useEffect(() => {
    dispatch({ type: "SET_LANGUAGE", language })
  })

  return (
    <Layout header={header} footer={footer}>
      <SEO title={heading} />
      <div className="col-12 col-lg-10 offset-lg-1 pain-page terms-and-conditions mt-lg-4 mb-lg-5 mb-4 mt-5 pt-lg-5 px-3 px-lg-2">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center">{heading}</h1>
          </div>
        </div>
        <div className="row">
          {content.map((richText, index) => {
            return (
              <div
                key={index}
                className={`col-12 col-md-6 mt-lg-4 mt-2`}
              >
                <Markdown>{richText}</Markdown>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Conditions

export const pageQuery = graphql`
  query Conditions($id: ID!, $language: STRAPI_JSON!) {
    strapi {
      plainpage(id: $id) {
        language
        heading
        richText {
          richText
        }
      }
      homepages(where: $language) {
        header {
          logo {
            url
          }
        }
        footer {
          copyright
          navItems {
            label
            slug
          }
        }
      }
    }
  }
`
